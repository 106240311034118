<template>
  <div class="wallet">
    <div class="wallet-title">{{ $t('Wallet.zichan') }}</div>

    <div class="total">
      <div>{{ $t('Wallet.total') }}</div>
      <div class="total-val main-color">{{ total.toFixed(4) }} USDT</div>
    </div>

    <div class="btn-row">
      <div class="btn-item" @click="tolink('Chongzhi')">
        <img src="@/assets/img/wallet/chongzhi.png" />
        <div>{{ $t('Wallet.chongzhi') }}</div>
      </div>
      <div class="btn-item" @click="tolink('tixian')">
        <img src="@/assets/img/wallet/tixian.png" />
        <div>{{ $t('Wallet.tixian') }}</div>
      </div>
    </div>

    <div class="wallet-min-title">{{ $t('Wallet.zichan') }}</div>

    <div class="wallet-list">
      <div class="wallet-item" v-for="item in walletList" :key="item.id" v-show="item.cid != 4 && item.cid != 5">
        <div class="wallet-name row items-center" style="flex-shrink: 0;">
          <img src="@/assets/img/logo.png" v-if="item.cid == 1" />
          <img src="@/assets/img/logo.png" v-if="item.cid == 6" />
          <img src="@/assets/img/base/usdt.svg" v-else />
          {{ item.cnameZh }}
        </div>
        <div style="width: 100%; display: flex;justify-self: flex-start; flex-wrap: wrap;">
          <div class="min-btn sell-btn" v-if="item.cid == 1" @click="tolink('Convert?lx=2')">{{ $t('Wallet.maichu') }}
          </div>
          <div class="min-btn cz-btn" v-if="item.cid == 2" @click="tolink('Chongzhi')">{{ $t('Wallet.chongzhi') }}
          </div>
          <div class="min-btn" v-if="item.cid == 2" @click="tolink('Tixian')">{{ $t('Wallet.tixian') }}</div>
          <div class="min-btn buy-btn" v-if="item.cid == 3 && usdaToCcai == 1" @click="tolink('Convert?lx=1')">{{ $t('Wallet.mairu') }}
          </div>
          <div class="min-btn" v-if="item.cid == 3" @click="tolink('Convert?lx=3')">{{ $t('Wallet.shandui') }}</div>
          <div class="min-btn" v-if="item.zz" @click="tolink('Zhuanzhang?id=' + item.cid)">{{ $t('Wallet.zhuanzhang')
            }}</div>
          <div class="min-btn" v-if="item.cid == 6 && ccaiToUsdt == 1" @click="tolink('Convert?lx=2')">{{ $t('Market.maichu') }}</div>
        </div>
        <div class="text-right" style="flex-shrink: 0;">
          <div class="main-color">{{ item.jine.toFixed(4) }}</div>
          <div class="freeze row">
            {{ $t('Wallet.dongjie') }}：
            <span v-if="item.cid == 1">{{ isht == 1 ? htAmountEds.toFixed(4) : "0.0000" }}</span>
            <span v-else-if="item.cid == 3">{{ isht == 1 ? htAmountUsde.toFixed(4) : "0.0000" }}</span>
            <span v-else>0.0000</span>
          </div>
        </div>
      </div>
    </div>

    <tab-bar active="4" ref="tabbarRef"></tab-bar>
  </div>
</template>

<script>
import { ref } from 'vue'
import TabBar from '@/components/TabBar'

export default {
  components: {
    TabBar
  },
  setup() {
    return {
      us: ref(null),
      walletList: ref([]),
      total: ref(0),
      edsPrice: ref(0),
      usdaToCcai:ref(0),
      ccaiToUsdt:ref(0),
      htAmountUsde: ref(0),
      htAmountEds: ref(0),

      isht: ref(0)
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    if (this.us) {
      this.getUser()
      this.getEdsPrice()
    }
  },
  methods: {
    tolink(path) {
      this.$router.push(path)
    },
    getUser() {
      let _this = this
      _this.$request.post(
        "api/Users/Get",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              message: res.data.msg,
            });
          }
          let data = res.data.data.us
          _this.isht = data.isht
        }
      )
    },
    getEdsPrice() {
      let _this = this
      _this.$request.post(
        "api/WalletsZhuanhuan/GetEdsPrice",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              message: res.data.msg,
            });
          }
          let data = res.data.data
          console.log(data)
          _this.edsPrice = data.edsPrice
          _this.usdaToCcai = data.usdaToCcai
          _this.ccaiToUsdt = data.ccaiToUsdt
          _this.getWallet()
        }
      )
    },
    getWallet() {
      let _this = this
      _this.$request.post(
        "api/Wallets/GetWallet",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              message: res.data.msg,
            });
          }
          let data = res.data.data
          let total = 0
          data.forEach(item => {
            if (item.cid == 6) {
              total += item.jine * _this.edsPrice
            } else {
              total += item.jine
            }
          })
          _this.walletList = data
          let djusde = data.find(item => item.cid == 4)
          let djeds = data.find(item => item.cid == 5)
          _this.htAmountUsde = djusde.jine
          _this.htAmountEds = djeds.jine
          _this.total = total
          _this.zzSelect()
        }
      )
    },
    zzSelect() {
      let _this = this
      _this.$request.post(
        "api/WalletsZhuanzhang/ZhuanzhangSelect",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              message: res.data.msg,
            });
            return
          }
          let data = res.data.data
          _this.walletList.forEach(item => {
            let find = data.find(item2 => item2.cid == item.cid)
            if (find) item.zz = true
          })
        }
      )
    }
  }
}
</script>

<style scoped>
.wallet {
  min-height: 100vh;
  padding: 20px 12px;
  box-sizing: border-box;
  background-image: linear-gradient(to right top, #2d8998, #519bab, #6fadbe, #8cc0d1, #a8d3e4, #b1dbec, #bbe2f3, #c4eafb, #bde8fa, #b7e5fa, #b0e3f9, #a9e1f8);
}

.wallet-title {
  font-size: 20px;
  font-weight: bold;
}

.total {
  margin-top: 15px;
  padding: 14px 20px 24px;
  background-color: #fff;
  border-radius: 10px;
  color: #818a9b;
}

.total-val {
  margin-top: 10px;
  font-size: 16px;
}

.btn-row {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

.btn-item {
  padding: 6px 0;
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99rem;
  background-color: #fff;
}

.btn-item img {
  margin-right: 10px;
  width: 35px;
  height: 35px;
}

.wallet-list {
  margin-top: 15px;
}

.wallet-item {
  margin-bottom: 10px;
  padding: 14px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #fff;
}

.wallet-item img {
  margin-right: 10px;
  width: 25px;
  height: 25px;
}

.wallet-name {
  width: 80px;
}

.freeze {
  font-size: 13px;
  color: gray;
}

.min-btn {
  padding: 0 4px;
  margin-right: 5px;
  margin-bottom: 5px;
  min-width: 50px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 12px;
  font-weight: normal;
  color: #fff;
  background-color: #2C8B9F;
}

.buy-btn {
  background-color: #5ac725;
}

.sell-btn {
  background-color: #f56c6c;
}

.cz-btn {
  background-color: #6EADFD;
}

.wallet-min-title {
  margin-top: 15px;
  font-size: 14px;
}
</style>